<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-7">
                <h5 class="mb-0">
                  <b>
                    Subasta # {{ form.id }} crudos Semanas
                    {{ numeroSemanaIni }} y {{ numeroSemanaFin - 1 }} ({{
                      form.fecha_ini
                    }}
                    / {{ form.fecha_fin }})
                  </b>
                </h5>
              </div>
              <div class="col-sm-5">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Comercio</li>
                  <li class="breadcrumb-item active">Gestión Subastas</li>
                  <li class="breadcrumb-item active">Ofertar</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-body" style="font-size: 11px">
                <div class="row">
                  <div class="col-lg-3 col-6">
                    <div
                      class="small-box alert-default-primary p-1"
                      style="min-height: 105px"
                    >
                      <div class="inner">
                        <div class="col-md-11">
                          <h5><b>Producto</b></h5>
                          <div v-for="item in form.productos" :key="item.id">
                            Crudo {{ item.producto.nombre }} ({{ item.api }} API
                            y {{ item.s }} %S)
                          </div>
                        </div>
                      </div>
                      <div class="icon">
                        <i class="ion ion-bag"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-6">
                    <div
                      class="small-box alert-default-success p-1"
                      style="min-height: 105px"
                    >
                      <div class="inner">
                        <div class="col-md-11">
                          <h5><b>Punto de Entrega</b></h5>
                          <div v-for="item in form.productos" :key="item.id">
                            Crudo {{ item.producto.nombre }} :
                            {{ item.sitio.dirreccion }}
                          </div>
                        </div>
                      </div>
                      <div class="icon">
                        <i class="ion ion-location"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-6">
                    <div
                      class="small-box alert-default-info p-1"
                      style="min-height: 105px"
                    >
                      <div class="inner">
                        <div class="col-md-11">
                          <h5><b>Volumen</b></h5>
                          <div v-for="item in form.productos" :key="item.id">
                            Crudo {{ item.producto.nombre }} :
                            {{ item.volumen }} BD entre el
                            {{ getFormattedDate(item.fecha_ini) }} y el
                            {{ getFormattedDate(item.fecha_fin) }} ({{
                              item.cant_vh
                            }}
                            Ctks/D)
                          </div>
                        </div>
                      </div>
                      <div class="icon">
                        <i class="ion ion-soup-can-outline"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-6">
                    <div
                      class="small-box alert-default-danger p-1"
                      style="min-height: 105px"
                    >
                      <div class="inner">
                        <div class="col-md-11">
                          <h5><b>Vigencia</b></h5>
                          Semana S (Comprendida de lunes a domingo) <br />
                          Semanas {{ numeroSemanaIni }} y
                          {{ numeroSemanaFin - 1 }} ({{ form.fecha_ini }} /
                          {{ form.fecha_fin }})
                        </div>
                      </div>
                      <div class="icon">
                        <i class="ion ion-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="small-box alert-default-warning">
                      <div class="inner">
                        <div class="col-md-12">
                          <h5><b>Precio</b></h5>
                          <div class="row">
                            <div
                              v-for="(item, indice) in form.productos"
                              :key="item.id"
                              class="col-md-4"
                            >
                              <b style="font-style: italic; font-size: 1.1em">
                                Para el crudo {{ item.producto.nombre }}<br />
                              </b>

                              Precio Oferta: Brent
                              <b v-if="item.precio_piso != null">{{
                                item.precio_piso != 0
                                  ? item.nSigno +
                                    " " +
                                    item.precio_piso +
                                    " " +
                                    item.nSignoMoneda
                                  : item.nSigno + " " + "Oferta"
                              }}</b>
                              <b v-else> + Oferta </b>
                              <br />
                              <div class="row pl-2">
                                <div class="class-md-4">Oferta:</div>
                                <div
                                  class="class-md-4"
                                  v-if="oferta[indice] != undefined"
                                >
                                  <input
                                    type="number"
                                    class="form-control form-control-sm"
                                    v-model="oferta[indice].precio_oferta"
                                    :disabled="accion == 2 || estado >= 2"
                                  />
                                </div>
                                <div class="class-md-4">US$B</div>
                              </div>
                              Brent: Ice Brent Combined Monthly Rollover Series
                              1st Month Close (LOc1)
                              <br />
                              Promedio de la semana del programa de las entregas
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="icon">
                        <i class="ion ion-pricetags"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="small-box bg-dark h-100">
                      <div class="inner">
                        <div class="col-md-10">
                          <h5><b>Pago</b></h5>
                          Pago anticipado en la semana (S-1) anterior a la
                          semana de inicio de las entregas (S) en base al
                          volumen nominado y el precio estimado (Ver formula de
                          precio).
                        </div>
                      </div>
                      <div class="icon">
                        <i class="ion ion-cash text-white"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="small-box bg-dark h-100">
                      <div class="inner">
                        <div class="col-md-10">
                          <h5><b>Inicio de Despachos</b></h5>
                          El día lunes de la semana de entregas S. (Sujeto a
                          verificación de pago de anticipo).
                        </div>
                      </div>
                      <div class="icon">
                        <i class="ion ion-speakerphone text-white"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="small-box bg-dark h-100">
                      <div class="inner">
                        <div
                          class="col-md-5"
                          v-if="estado <= 2 || estado == null"
                        >
                          <h5><b>Estado</b></h5>
                          <select
                            id="estado"
                            class="form-control form-control-sm"
                            v-model="estado"
                            :disabled="
                              (accion == 1 && estado == 2 && id == null) ||
                              accion == 2
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div class="col-md-5" v-else>
                          <h5><b>Estado</b></h5>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            value="Definitivo"
                            :class="
                              estado == null || estado == ''
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            disabled
                          />
                        </div>
                      </div>
                      <div class="icon">
                        <i class="ion ion-podium text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-12">
                    <oferta-subastas-calendar
                      v-if="form.id"
                      ref="ofertaSubastasCalendar"
                      v-bind:calendar-mounted.sync="calendarMounted"
                    ></oferta-subastas-calendar>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                  <button
                    class="btn bg-primary col-md-1 ml-2"
                    @click="save()"
                    v-show="accion != 2 && estado != null"
                  >
                    <i class="fas fa-save"></i><br />Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import OfertaSubastasCalendar from "./OfertaSubastasCalendar.vue";
import moment from "moment";

export default {
  name: "OfertaSubastaForm",
  components: {
    Loading,
    OfertaSubastasCalendar,
  },
  data() {
    return {
      form: {},
      estado: null,
      formMaster: [],
      formDet: {},
      oferta: [],
      listasForms: {
        estados: [],
      },
      shouldShowButton: true,
      metodo: "",
      id: this.$route.params.id,
      cargando: false,
      accion: null,
      calendarMounted: false,
    };
  },

  methods: {
    async init() {
      this.form = this.$route.params.data_edit;
      this.accion = this.$route.params.acto;
      this.oferta = [];
      /*  if (this.accion == 1) {
        this.oferta = new Array(this.form.productos.length);
        this.oferta.fill(null);
      } else { */
      await this.$nextTick(); // Esperar a que se actualice el DOM
      this.calendarMounted = true;
      if (this.form.ofertas.length > 0) {
        this.estado = this.form.ofertas[0].estado;
      }
      this.form.productos.forEach((prods, i) => {
        this.oferta.push({
          id: null,
          precio_oferta: null,
        });
        this.form.ofertas.forEach((ofert) => {
          if (
            prods.producto_liquido_id ==
            ofert.subasta_producto.producto_liquido_id
          ) {
            this.oferta[i].id = ofert.id;
            this.oferta[i].precio_oferta = ofert.precio_oferta;
          }
          ofert.detalles.forEach((det, index) => {
            if (
              prods.producto_liquido_id ==
              ofert.subasta_producto.producto_liquido_id
            ) {
              let component = this.$refs.ofertaSubastasCalendar;
              const colorIndex = index % component.colores.length;
              const event = {
                title: "Nominación CTks/D",
                start: det.fecha,
                end: moment(det.fecha).add(1, "days"),
                allDay: true,
                idCalendar: component.calendarOptions.events.length + 1,
                producto: ofert.subasta_producto.producto.nombre,
                subProductId: ofert.com_subasta_producto_id,
                id: det.id,
                cantCtks: `CTks/D: ${det.cant_vehi}`,
                color: component.colores[colorIndex],
              };
              component.calendarOptions.events.push(event);
            }
          });
        });
      });
      /* } */
    },

    back() {
      return this.$router.replace("/Com/OfertaSubastas");
    },

    getFormattedDate(dateString) {
      const [year, month, day] = dateString.split("-").map(Number);
      const monthNames = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];
      const monthName = monthNames[month - 1]; // Restamos 1 al mes para ajustar el índice
      return `${day} de ${monthName}`;
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    save() {
      this.cargando = true;
      let falt1 = [];
      let msg1 = "";
      this.formMaster = [];

      for (let i = 0; i < this.oferta.length; i++) {
        if (
          this.oferta[i] == undefined ||
          this.oferta[i] == "" ||
          this.oferta[i] == ""
        ) {
          falt1.push({
            producto: this.form.productos[i].producto.nombre,
          });
          msg1 +=
            msg1 == ""
              ? this.form.productos[i].producto.nombre
              : ", " + this.form.productos[i].producto.nombre;
        } else {
          this.formMaster.push({
            com_subasta_id: this.form.id,
            com_subasta_producto_id: this.form.productos[i].id,
            cliente_id: this.$store.state.user.cliente_id,
            precio_oferta: this.oferta[i].precio_oferta,
            id: this.oferta[i].id,
            estado: this.estado,
          });
        }
      }

      let array = this.$refs.ofertaSubastasCalendar.calendarOptions.events;
      this.formMaster.forEach((master) => {
        if (!("detalles" in master)) {
          master.detalles = [];
        }

        array.forEach((det) => {
          if (det.subProductId == master.com_subasta_producto_id) {
            master.detalles.push({
              id: det.id ? det.id : null,
              cant_vehi: det.cantCtks,
              fecha: det.start,
            });
          }
        });
      });

      if (falt1.length > 0) {
        this.cargando = false;
        this.$swal({
          title: "Completa la oferta!!",
          text: `Faltan los siguientes productos por ofertar: ${msg1}`,
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
      } else if (array.length == 0) {
        this.cargando = false;
        this.$swal({
          title: "Completa la oferta!!",
          text: `Favor diligenciar en el calendario los ctks por día.`,
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
      } else {
        this.cargando = false;
        this.$swal({
          title: "Esta seguro de realizar la oferta a esta subasta?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Realizar!",
        }).then((result) => {
          if (result.value) {
            this.cargando = true;
            axios({
              method: "POST",
              url: "/api/com/ofertaSubastas",
              data: { datos: this.formMaster, estado: this.estado },
            })
              .then((response) => {
                this.back();
                let data = response.data;
                this.cargando = false;
                this.$swal({
                  icon: "success",
                  title: data.msg,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              })
              .catch((e) => {
                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error" + e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.cargando = false;
              });
          }
        });
      }
    },

    getWeekNumber(date) {
      const onejan = new Date(date.getFullYear(), 0, 1);
      const weekNum = Math.ceil(
        ((date - onejan) / 86400000 + onejan.getDay() + 1) / 7
      );
      return weekNum;
    },
  },
  computed: {
    numeroSemanaIni() {
      const dateIni = new Date(this.form.fecha_ini); // Convert fecha_ini to a Date object
      return this.getWeekNumber(dateIni);
    },
    numeroSemanaFin() {
      const dateFin = new Date(this.form.fecha_fin); // Convert fecha_fin to a Date object
      return this.getWeekNumber(dateFin);
    },
  },

  async mounted() {
    await this.init();
    await this.getEstados();
  },
};
</script>
