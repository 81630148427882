var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('FullCalendar',{attrs:{"options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [_c('div',{staticClass:"custom-event-content col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"class-md-3"},[_c('b',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(arg.event.extendedProps.producto)+" | ")])]),_c('div',{staticClass:"class-md-3"},[_c('b',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(arg.event.extendedProps.cantCtks)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$parent.accion != 2),expression:"$parent.accion != 2"}],staticClass:"class-md-3"},[(_vm.$parent.estado == 1)?_c('button',{staticClass:"btn alert-default-danger btn-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteEvent(arg.event.extendedProps)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()])])])]}}])})],1)]),_c('div',{ref:"modalNominacionCtks",staticClass:"modal fade",attrs:{"id":"modal-form-nominacion-ctks"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Nominación CTks/D")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},_vm._l((_vm.$parent.form.productos),function(item,indice){return _c('div',{key:item.id,staticClass:"row"},[_c('label',{staticClass:"col-md-6"},[_vm._v(_vm._s(item.producto.nombre)+" (Hasta: "+_vm._s(item.cant_vh)+" Ctks/D)")]),_c('div',{staticClass:"form-group col-md-6"},[(
                  _vm.isWithinDateRange(
                    item.fecha_ini,
                    item.fecha_fin,
                    _vm.valoresEvents.startStr
                  )
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cantidadProductos[indice]),expression:"cantidadProductos[indice]"}],staticClass:"form-control form-control-sm",attrs:{"type":"number","max":parseFloat(item.cant_vh)},domProps:{"value":(_vm.cantidadProductos[indice])},on:{"change":function($event){return _vm.valCantVh(item.cant_vh, indice)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.cantidadProductos, indice, $event.target.value)}}}):_c('span',{staticClass:"badge badge-"},[_vm._v("No disponible")])])])}),0),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }