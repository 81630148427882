<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <FullCalendar :options="calendarOptions"
          ><template v-slot:eventContent="arg">
            <div class="custom-event-content col">
              <div class="row">
                <div class="class-md-3">
                  <b style="font-size: 10px"
                    >{{ arg.event.extendedProps.producto }} |
                  </b>
                </div>
                <div class="class-md-3">
                  <b style="font-size: 10px">
                    {{ arg.event.extendedProps.cantCtks }}
                  </b>
                </div>
                <div
                  class="class-md-3"
                  v-show="$parent.accion != 2"
                >
                  <button
                    type="button"
                    class="btn alert-default-danger btn-small"
                    @click="deleteEvent(arg.event.extendedProps)"
                    v-if="$parent.estado == 1"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div> </template
        ></FullCalendar>
      </div>
    </div>
    <div
      class="modal fade"
      id="modal-form-nominacion-ctks"
      ref="modalNominacionCtks"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Nominación CTks/D</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="row"
              v-for="(item, indice) in $parent.form.productos"
              :key="item.id"
            >
              <label class="col-md-6"
                >{{ item.producto.nombre }} (Hasta:
                {{ item.cant_vh }} Ctks/D)</label
              >
              <div class="form-group col-md-6">
                <input
                  v-if="
                    isWithinDateRange(
                      item.fecha_ini,
                      item.fecha_fin,
                      valoresEvents.startStr
                    )
                  "
                  type="number"
                  class="form-control form-control-sm"
                  v-model="cantidadProductos[indice]"
                  @change="valCantVh(item.cant_vh, indice)"
                  :max="parseFloat(item.cant_vh)"
                />
                <span class="badge badge-" v-else>No disponible</span>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import listGridPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/es";
import moment from "moment";

export default {
  components: {
    FullCalendar,
  },

  data() {
    return {
      calendarOptions: {
        plugins: [
          listGridPlugin,
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
        ],
        initialDate: new Date(),
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridWeek,dayGridMonth,listDay",
        },
        initialView: "dayGridWeek",
        locale: esLocale,
        height: 350,
        dayHeaderContent: this.customDayHeaderContent,
        editable: true,
        selectable: true,
        select: this.handleDateSelect,
        events: [],
      },
      valoresEvents: [],
      cantidadProductos: [],
      productoNombre: null,
      colores: [
        "#cce5ff",
        "#d4edda",
        "#fff3cd",
        "#f8d7da",
        "#d6d8d9",
        "#d1ecf1",
      ],
    };
  },

  created() {
    const fechaFin = moment(this.$parent.form.fecha_fin).add(1, "days");
    this.calendarOptions.validRange = {
      start: this.$parent.form.fecha_ini,
      end: fechaFin.toISOString(),
    };
  },

  methods: {
    customDayHeaderContent({ date }) {
      const options = {
        weekday: "short",
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };
      return new Intl.DateTimeFormat("es-ES", options).format(date);
    },

    isWithinDateRange(startDate, endDate, selectedDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const selected = new Date(selectedDate);

      return selected >= start && selected <= end;
    },

    save() {
      const events = [];
      const formEvent = [];

      for (let i = 0; i < this.cantidadProductos.length; i++) {
        if (this.cantidadProductos[i]) {
          const colorIndex = i % this.colores.length;
          formEvent.push({
            id: null,
            producto: this.$parent.form.productos[i].producto.nombre,
            subProductId: this.$parent.form.productos[i].id,
            cantidad: this.cantidadProductos[i],
            color: this.colores[colorIndex],
          });
        }
      }

      formEvent.forEach((evento, index) => {
        if (evento) {
          //Valida si es de un solo día
          if (this.valoresEvents.start == this.valoresEvents.end) {
            const event = {
              id: evento.id,
              title: "Nominación CTks/D",
              start: this.valoresEvents.startStr,
              end: this.valoresEvents.endStr,
              allDay: this.valoresEvents.allDay,
              idCalendar: this.calendarOptions.events.length + 1,
              producto: evento.producto,
              subProductId: evento.subProductId,
              cantCtks: `CTks/D: ${evento.cantidad}`,
              color: evento.color,
            };
            this.calendarOptions.events.push(event);
          } else {
            //Valida si son varios días
            let fechaActual = new Date(this.valoresEvents.start);
            let fechaFin = this.valoresEvents.end;
            let dia = this.valoresEvents.start;
            while (fechaActual < fechaFin) {
              const event = {
                id: evento.id,
                title: "Nominación CTks/D",
                start: moment(dia).format("YYYY-MM-DD"),
                end: moment(dia).format("YYYY-MM-DD"),
                allDay: this.valoresEvents.allDay,
                idCalendar: this.calendarOptions.events.length + 1,
                producto: evento.producto,
                subProductId: evento.subProductId,
                cantCtks: `CTks/D: ${evento.cantidad}`,
                color: evento.color,
              };
              this.calendarOptions.events.push(event);
              dia = fechaActual.setDate(fechaActual.getDate() + 1);
            }
          }
        }
      });
      window.$("#modal-form-nominacion-ctks").modal("hide");
      this.cantidadProductos = [];
    },

    async handleDateSelect(selectInfo) {
      if (this.$parent.accion != 2) {
        window.$("#modal-form-nominacion-ctks").modal("show");
        this.valoresEvents = selectInfo;
      }
    },

    deleteEvent(event) {
      const eventIndex = this.calendarOptions.events.findIndex(
        (item) => item.idCalendar === event.idCalendar
      );
      if (eventIndex !== -1) {
        this.calendarOptions.events.splice(eventIndex, 1);
      }

      for (let i = 0; i < this.calendarOptions.events.length; i++) {
        this.calendarOptions.events[i].idCalendar = i + 1;
      }
    },

    valCantVh(cant, indice) {
      let cantVh = parseInt(cant);
      let cantForm = parseInt(this.cantidadProductos[indice]);
      if (this.cantidadProductos[indice]) {
        // Se valida que la fecha inicial sea menor que la fecha final
        if (cantForm > cantVh) {
          this.cantidadProductos = [];
          this.$swal({
            icon: "error",
            title:
              `La cantidad digitada no puede superar a la cantidad ` +
              cant +
              " " +
              "tks/D establecida.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
  },
};
</script>

<style>
.fc-toolbar,
.fc-toolbar.fc-header-toolbar {
  padding: 0px;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1px;
}
.custom-event-content {
  font-size: 12px;
  font-weight: bold;
  color: rgb(51, 51, 50);
}
.btn-small {
  font-size: 11px;
  padding: 0px 2px;
  margin-left: 8px;
}
</style>
